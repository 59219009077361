import request from '@/utils/request';

// 液位仪信息
export function QueryLevelGauge(params) {
  return request({
    url: '/ye_meter/data',
    method: 'get',
    params
  });
}

export function LevelGaugeAdd(params) {
  return request({
    url: '/ye_meter/add_form',
    method: 'get',
    params
  });
}

export function LevelGaugeEdit(params) {
  return request({
    url: '/ye_meter/edit_form',
    method: 'get',
    params
  });
}

export function LevelGaugeDel(params) {
  return request({
    url: '/ye_meter/del',
    method: 'get',
    params
  });
}

export function ExportLevelGauge(params) {
  return request({
    url: '/ye_meter/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 油罐信息
export function QueryOilTank(params) {
  return request({
    url: '/oil_tank/data',
    method: 'get',
    params
  });
}

export function OilTankAdd(params) {
  return request({
    url: '/oil_tank/add_form',
    method: 'get',
    params
  });
}

export function OilTankEdit(params) {
  return request({
    url: '/oil_tank/edit_form',
    method: 'get',
    params
  });
}

export function getTankerAll(params) {
  return request({
    url: '/oil_tank/get_tanker_all',
    method: 'get',
    params
  });
}

export function getPortGunAll(params) {
  return request({
    url: '/oil_tank/get_port_gun_all',
    method: 'get',
    params
  });
}

export function OilTankDel(params) {
  return request({
    url: '/oil_tank/del',
    method: 'get',
    params
  });
}

export function GetOilTank(params) {
  return request({
    url: '/oil_tank/get_oil_tank',
    method: 'get',
    params
  });
}

export function ExportOilTank(params) {
  return request({
    url: '/oil_tank/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

// 液位数据
export function QueryLevelGaugeData(params) {
  return request({
    url: '/ye_meter_record/data',
    method: 'get',
    params
  });
}

export function ExportLevelGaugeData(params) {
  return request({
    url: '/ye_meter_record/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

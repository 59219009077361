export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: {
      customRender: 'index'
    }
  },
  {
    title: '加油站名称',
    key: 'siteName',
    align: 'left',
    minWidth: 150
  },
  {
    title: '油罐数量',
    key: 'tankNum',
    minWidth: 100
  },
  {
    title: '油品',
    key: 'type',
    minWidth: 80
  },
  {
    title: '总容量(L)',
    key: 'oilCapacity',
    minWidth: 100
  },
  {
    title: '液位仪编号',
    key: 'meterNum',
    minWidth: 100
  },
  // {
  //   title: '油罐高度(mm)',
  //   key: 'tankHeight'
  // },
  {
    title: '操作',
    fixed: 'right',
    key: 'actions',
    width: 100,
    scopedSlots: {
      customRender: 'actions'
    }
  }
];

<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item label="">
          <el-select
            v-model="searchParams.siteId"
            filterable
            :clearable="true"
            placeholder="加油站(可输入)"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="searchParams.type"
            filterable
            :clearable="true"
            placeholder="油品"
          >
            <el-option
              v-for="item in typeData"
              :key="item.value"
              :value="item.value"
              :label="item.type"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <!-- <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="
              visible = true;
              dialogAdd = true;
            "
            class="export-btn"
            >新增</el-button
          > -->
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            class="export-btn"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
      stripe
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          type="primary"
          size="small"
          @click="handleGoDetail(scope.row)"
          >详情</el-button
        >
        <!-- <el-button type="primary" size="small" @click="handleGetRow(scope.row)"
          >编辑</el-button
        > -->
        <!-- <el-button type="danger" size="small" @click="handleDel(scope.row.id)"
          >删除</el-button
        > -->
      </template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import {
  QueryOilTank,
  ExportOilTank,
  OilTankDel
} from '@/api/level_gauge_info';

import DefaultTable from '@/components/DefaultTable/new';
import { columns } from './columns/OilTank';
// import { getGasData } from '@/api/global';
import mixins from '@/mixins';

export default {
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    const _type = [
      { type: '92#', value: '92', type_value: '92#' },
      { type: '95#', value: '95', type_value: '95#' },
      { type: '98#', value: '98', type_value: '98#' },
      { type: '0#', value: '0', type_value: '0#' },
      { type: '-10#', value: '-10', type_value: '-10#' }
    ];
    return {
      tableLoading: true,
      dialogAdd: true,
      searchParams: {},
      typeData: _type,
      columns,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  mixins: [mixins],
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.tableLoading = true;
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryOilTank(params).then(res => {
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    handleCurrentChange(current) {
      this.pagination.current = current;
      this.handleQuery({
        current,
        pageSize: this.pagination.pageSize
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };
      ExportOilTank(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleDel(id) {
      this.$confirm('是否删除该油罐？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          OilTankDel({ id }).then(res => {
            console.log(res);
            if (res.code === 0) {
              this.$message.success(res.msg);
              this.handleQuery({
                current: this.pagination.current,
                pageSize: this.pagination.pageSize
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    handleGoDetail(data) {
      console.log(data);
      this.$router.push({
        path: '/gauge/tank_detail',
        query: {
          id: data.siteId,
          name: data.siteName,
          meterNum: data.meterNum
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
